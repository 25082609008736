@import url("./variables.css");
@import url("./font.css");

*,
::after,
::before {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
form,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
   padding: 0;
   margin: 0;
   -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
}
li {
   list-style-type: none;
}
button {
   cursor: pointer;
   border: none;
}
img {
   width: 100%;
}
html {
   box-sizing: border-box;
   font-family: "Montserrat", sans-serif;
   line-height: var(--global--line-height-body);
}
.pageNotFound{
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 30px;
  color: var(--color-white);
}
.pageNotFound div{
  text-align: center;
}
.pageNotFound h1{
  font-size: 20px;
}
.pageNotFound img{
  display: inline-block;
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}
.promotionBanner {
   display: block;
}
.promotionBannerMobile {
   display: none;
}
body {
   background-color: #000225;
}
.loadingGames {
   max-width: 1300px;
   margin: 0 auto;
   display: flex;
   justify-content: center;
   color: var(--color-white);
   padding-block: 50px;
}
.loadingGames img {
   width: 60px;
   margin-bottom: 20px;
}
.loadingGames div {
   text-align: center;
}
.backPage {
   display: flex;
   justify-content: space-between;
   margin-block: 10px;
   font-size: 13px;
   margin-top: 15px;
   margin-bottom: 15px;
}
.backPage button {
   background: none;
   color: var(--color-white);
   text-transform: uppercase;
   display: flex;
   align-items: center;
   gap: 10px;
}
.backPage button img {
   width: 30px;
   rotate: 90deg;
}
.backPage a {
   color: white;
   text-decoration: none;
}
.backPage .active {
   font-weight: bold;
}
.header-news-logout {
   max-width: 1300px;
   margin: 0 auto;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-top: 20px;
}
.news-container {
   font-size: 16px;
   background: linear-gradient(90deg, #35395e 0%, #35395e 50.5%, #35395e 100%);
   color: #73c2fb;
   padding: 10px 32px;
   display: flex;
   overflow: hidden;
   border-radius: 10px;
   width: 100%;
}
.news-heading {
   padding: 0 10px 0 0;
}
.news-container .news {
   position: relative;
   width: 100%;
}
.news-container .news-single {
   color: var(--color-white);
   text-decoration: none;
   font-weight: normal;
   opacity: 0;
   position: relative;
   top: 0;
   left: 0;
   transform: translateY(-8px);
   transition: all 400ms ease;
   pointer-events: none;
   display: none;
}
.news-container .news-single.active {
   opacity: 1;
   transform: translateY(0);
   pointer-events: auto;
   display: block;
}
.post-template-default article,
.category article,
.blog article {
   width: 100%;
   max-width: 1080px;
   margin: auto;
   font-size: 0.8em;
   color: #d2d2d5;
}
.post-template-default article a,
.category article a,
.blog article a {
   color: var(--color-white);
}
.post-template-default article strong,
.category article strong,
.blog article strong {
   color: var(--color-white);
}
.post-template-default article h2,
.category article h2,
.blog article h2 {
   color: var(--color-white);
   font-size: 2.6em;
   font-weight: 600;
}
.post-template-default article h3,
.category article h3,
.blog article h3 {
   color: var(--color-white);
   font-weight: 600;
}
.post-template-default article h4,
.category article h4,
.blog article h4 {
   color: var(--color-white);
   font-size: 1.2em;
}
.single .site-main > article > .entry-header {
   border-bottom: 3px solid #3c414b;
   margin-bottom: 32px;
}
.single .site-main > article > .entry-footer {
   border-top: 3px solid #3c414b;
}
.post-template-default header#masthead,
.category header#masthead,
.blog header#masthead {
   background-color: #000;
}
.byline {
   display: none !important;
}
.post-template-default a:hover,
.category a:hover,
.blog a:hover {
   color: #83898d;
}
.posted-on {
   color: var(--color-white);
}
.blog .site-main article,
.category .site-main article {
   background-color: #00000026;
   padding: 20px;
   margin: 30px auto;
   border-radius: 10px;
   border: solid 1px #58585870;
   backdrop-filter: blur(10px);
   cursor: pointer;
   transition: all 0.3s;
}
.blog .site-main article:hover,
.category .site-main article:hover {
   background-color: #212325;
   border: solid 1px #ffffff75;
   box-shadow: 0 0 6px #ffffff47, inset 0 0 16px #5151519c;
}
.post-template-default .site-main,
.category .site-main,
.blog .site-main {
   background-attachment: fixed;
   padding: 15px;
   padding-bottom: 20px;
   padding-top: 150px;
   min-height: 500px;
}
.post-template-default .alignwide {
   display: block;
}
.post-template-default .alignwide h1.entry-title {
   font-size: 3.5em;
   font-weight: bold;
   color: var(--color-white);
   padding-top: 40px;
}
.category article h2.entry-title,
.blog article h2.entry-title {
   font-size: 1.1em;
   margin: 10px 0px;
   margin-top: 0px;
   font-weight: bold;
   color: #ddd;
}
.category article:hover h2.entry-title,
.blog article:hover h2.entry-title {
   color: var(--color-white);
}
.category article p,
.blog article p {
   font-size: 0.85em;
}
.post-template-default .site-main ol {
   padding-left: 16px;
}
.post-template-default .site-main ul {
   padding-left: 16px;
}
#gameSearchBox {
   background-color: #0000007a;
   border: solid 1px #fff3;
   border-radius: 45px;
   font-size: 0.7em;
   color: var(--color-white);
   text-align: center;
   padding: 10px 15px;
   width: 100%;
   max-width: 250px;
   margin-top: 30px;
   position: relative;
}
.searchBoxCont {
   max-width: 1140px;
   margin: auto;
   text-align: center;
}
.partners {
   max-width: 100%;
}
.headerVipIcon {
   width: 36px;
   vertical-align: top;
}
.slotsContent div .game {
   z-index: 100;
}
.pageCont {
   background-attachment: fixed;
   padding: 60px 15px;
   padding-top: 120px;
}
.bal-subcontainer .sub-bal {
   display: block;
   font-size: 1.2em;
   color: var(--color-white);
   font-weight: 600;
}
.pum-theme-verification {
   backdrop-filter: blur(10px);
}
.pum-theme-1086 .popmake-close {
   display: none;
}
.verification-certification img {
   transform: scale(0.7);
   margin-top: 29px;
}
.verification-logo {
   margin-bottom: 36px;
}
.side-nav::-webkit-scrollbar {
   width: 10px;
}
/* Track */
.side-nav::-webkit-scrollbar-track {
   background: #ffffff00;
}
/* Handle */
.side-nav::-webkit-scrollbar-thumb {
   background: #5a1717;
}
/* Handle on hover */
.side-nav::-webkit-scrollbar-thumb:hover {
   background: #742222;
}
.viber-mobile {
   display: none;
}
.account-content {
   width: 100%;
   max-width: 1400px;
   margin: auto;
   padding: 0px;
}
.account-navigation {
   display: flex;
   justify-content: end;
   padding-top: 25px;
}
.fix-container {
   display: flex;
   gap: 4px;
}
.account-navigation a {
   color: #d7d7d7;
   text-decoration: none;
   font-size: 1em;
   padding: 10px;
   display: block;
   border-bottom: solid var(--color-white);
}
.mobileFooterNav {
   display: none;
   position: fixed;
   bottom: 0;
   z-index: 99999;
   background-color: #000;
   width: 100%;
}
.mobileFooterNav img{
   width: 50px;
   height: 50px;   
}
@media (max-width: 1270px) {
   .main-wrapper,
   .text-content-area,
   .sg8-footer-main,
   .sg8-footer-bottom {
      padding-inline: 20px;
   }
}
@media only screen and (max-width: 856px) {
   .payment-method {
      display: block;
   }
   .user-access .register-mobile {
      vertical-align: top;
   }
   .account-navigation {
      position: relative;
      float: unset;
      max-width: unset;
      padding-top: 0px;
   }
   .fix-container {
      position: relative;
      display: flex;
      overflow: auto;
      max-width: unset;
   }
   .account-navigation a {
      width: 100%;
      white-space: nowrap;
      padding: 22px 30px;
   }
   .account-content {
      width: 100%;
      padding: 0px;
   }
   .footer-flex > .social-icons {
      clear: left;
   }
   .slider-thumb-mobile img {
      aspect-ratio: 1.3;
   }
   .homepage {
      padding-top: 60px;
   }
   .viber-desktop {
      display: none;
   }
   .viber-mobile {
      display: unset;
   }
}
.footer-phone {
   position: relative;
}
.footer-phone span {
   position: absolute;
   display: block;
   white-space: nowrap;
   background-color: #000000d9;
   font-size: 0.8em;
   padding: 5px 15px;
   color: var(--color-white);
   border: solid 1px #303030;
   border-radius: 20px;
   top: 2vh;
   right: -7vh;
   display: none;
   z-index: 9;
}
.footer-phone:hover span {
   display: block;
}
.viber-desktop {
   position: relative;
}
.viber-desktop span {
   position: absolute;
   display: block;
   white-space: nowrap;
   background-color: #000000d9;
   font-size: 0.8em;
   padding: 5px 15px;
   color: var(--color-white);
   border: solid 1px #303030;
   border-radius: 20px;
   top: 2vh;
   right: -7vh;
   display: none;
   z-index: 9;
}
.all-form-container button {
   margin-top: 20px;
}
.viber-desktop:hover span {
   display: block;
}
.footer-whatsapp {
   position: relative;
}
.footer-whatsapp span {
   position: absolute;
   display: block;
   white-space: nowrap;
   background-color: #000000d9;
   font-size: 0.8em;
   padding: 5px 15px;
   color: var(--color-white);
   border: solid 1px #303030;
   border-radius: 20px;
   top: 2vh;
   right: -7vh;
   display: none;
   z-index: 9;
}
.footer-whatsapp:hover span {
   display: block;
}
.provider-selection .owl-stage-outer {
   overflow: unset !important;
}
.provider-selection .autohide-arrows .owl-nav {
   display: block !important;
}
.provider-selection .black .sa_owl_theme .owl-nav .owl-prev {
   left: -28px;
   border-radius: 5px;
   background-size: 14px !important;
   opacity: 0.8;
   box-shadow: 0px 0px 6px #fb0000, inset 0px 0px 12px #ff0000;
}
.provider-selection .black .sa_owl_theme .owl-nav .owl-next {
   right: -28px;
   border-radius: 5px;
   background-size: 14px !important;
   opacity: 0.8;
   box-shadow: 0px 0px 6px #fb0000, inset 0px 0px 12px #ff0000;
}
.provider-selection .black .sa_owl_theme .owl-nav .owl-prev:hover,
.provider-selection .black .sa_owl_theme .owl-nav .owl-next:hover {
   background-color: rgb(169 0 0) !important;
}
.provider-selection .item {
   background-image: radial-gradient(
      80% 82% at 50% -24%,
      #7c7c7c40 0,
      #00000099 100%
   );
   border-radius: 6px;
   border: solid 1px #2c2c2c70;
   box-shadow: 0px 0px 4px #0a0a0a, inset 0px 0px 12px #3030308c;
   padding: 0px;
   margin: 5px;
   filter: drop-shadow(0px 5px 5px #111);
   transition: all 0.3s;
}
.provider-selection .owl-item:hover .item {
   border-radius: 6px;
   border: solid 1px #cd0000b5;
   box-shadow: 0px 0px 10px #af0000, inset 0px 0px 12px #ff00008c;
}
.provider-selection .owl-item {
   transition: all 0.3s;
}
.provider-selection .owl-item:hover {
   transform: scale(1.1);
   z-index: 9;
}
.primary-menu-container .pll-parent-menu-item {
   display: none;
}
.primary-menu-container .pll-parent-menu-item > a > img {
   display: none;
}
.primary-menu-container .pll-parent-menu-item > a > span {
   margin-left: 0px !important;
}
.pll-parent-menu-item:before {
   content: "";
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   display: block;
   width: 26px;
   height: 26px;
   left: 13px;
   top: 6px;
   border-radius: 8px;
   position: absolute;
}
.mobile-language-selector {
   display: none;
}
.menu--show .mobile-language-selector {
   display: none;
}
.mobile-language-selector .language-dropdown {
   right: unset;
   left: 0px;
   top: 44px;
}
.mobile-language-selector img.flag-new {
   width: 36px;
}
.mob-live-nav:before {
   content: "";
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   display: block;
   width: 27px;
   height: 44px;
   left: 12px;
   position: absolute;
}
.site-logo .custom-logo {
   max-width: 120px;
}
.self-exclusion {
   font-size: 0.9em;
}
.self-exclusion p {
   margin: 15px 0px;
}
.self-exclusion ol {
   margin-left: 38px;
}
.self-exclusion ol li {
   padding-bottom: 5px;
}
.self-exclusion ul li {
   padding-bottom: 5px;
}
.exclusion-btn {
   text-align: center;
}
.exclusion-btn a.logout {
   margin-left: 0px;
   margin-top: 22px;
   font-size: 1em;
   background-image: radial-gradient(
      50% 100% at 50% 142%,
      #dd1212 0,
      #870404 100%
   );
   box-shadow: 0 0 5px #680000;
   display: inline-block;
}
.exclusion-btn a.logout.logged-user {
   display: none;
}
.logged-in .exclusion-btn a.logout {
   display: none;
}
.logged-in .exclusion-btn a.logout.logged-user {
   display: inline-block;
}
#popmake-2235 {
   padding: 30px;
}
nav.navigation.pagination {
   max-width: 1080px;
   border-top: 3px solid #272727;
}
.navigation .nav-links {
   color: var(--color-white);
}
span.page-numbers.current {
   font-size: 0.8em !important;
}
.navigation .nav-links > span {
   color: #afafaf;
   padding: 9px 0px;
   font-size: 0.7em !important;
}
a.page-numbers {
   color: #e9e9e9 !important;
   font-size: 0.7em !important;
   padding: 9px 15px;
   display: none;
}
.navigation .nav-links .dots {
   display: none;
}
a.next.page-numbers {
   display: unset;
}
a.prev.page-numbers {
   display: unset;
}
.navigation .nav-links {
   width: 100%;
   max-width: 1080px;
   margin: auto;
   font-size: 0.95em;
   justify-content: space-between;
}
.nav-next a {
   color: var(--color-white);
}
.nav-previous a {
   color: var(--color-white);
}
.post-navigation .meta-nav {
   color: var(--color-white);
   font-size: 0.8em;
}
.post-navigation .meta-nav {
   color: var(--color-white);
   font-size: 0.8em;
}
.nav-next a p.post-title {
   font-size: 0.8em;
   font-weight: 400;
}
.nav-previous a p.post-title {
   font-size: 0.8em;
   font-weight: 400;
}
.mob-blog-nav:before {
   content: "";
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   display: block;
   width: 25px;
   height: 42px;
   left: 12px;
   position: absolute;
}
.pum-theme-296 .pum-container,
.pum-theme-login-register .pum-container {
   padding: 0px;
}
.pum-theme-1971 .pum-container,
.pum-theme-login-register .pum-container {
   padding: 0px;
}
.reg-main-container {
   display: flex;
}
.pum-container.pum-responsive img {
   max-width: 100%;
   height: auto;
}
.promo-container-reg {
   background-color: #050203;
   width: 66%;
   border-radius: 11px 0px 0px 11px;
   overflow: hidden;
   display: flex;
   align-items: center;
}
.iframe-container {
   width: 100%;
   background-color: #0b0c0e;
   border-radius: 0px 11px 11px 0px;
   display: flex;
   align-items: center;
   overflow: hidden;
}
@media only screen and (max-width: 856px) {
   
   #popup-1 {
      position: relative;
      right: -20px;
      color: var(--color-white);
      padding-block: 10px;
      width: 120px;
      top: 83.083px !important;
      right: 20px !important;
      text-align: center;
      border-radius: 10px;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(30px);
      border: 1px solid #c2c2c2;
   }
   #popup-1 a {
      text-decoration: none;
      color: var(--color-white);
   }
   #popup-1 a {
      display: inline-block;
      padding-bottom: 5px;
      border-bottom: 2px solid rgba(255, 255, 255, 0);
   }
   #popup-1 a:hover {
      border-bottom: 2px solid rgb(255, 255, 255);
   }
   .popup-arrow {
      display: none;
   }
   .primary-menu-container .pll-parent-menu-item {
      display: block;
   }
   .mobile-language-selector {
      position: absolute;
      left: 160px;
      z-index: 9;
      top: 12px;
      display: none;
   }
   .promotionPage {
      padding-inline: 25px;
   }
   .menu--show .mobile-language-selector {
      display: block;
   }
   .user-access .lang-dropdown {
      display: none;
   }
   .provider-selection {
      position: absolute;
   }
   .site-logo .custom-logo {
      max-width: 84px;
      margin-top: 2px;
   }
}
@media only screen and (max-width: 756px) {
   .provider-selection {
      bottom: 136px;
   }
}
@media only screen and (max-width: 656px) {
   .provider-selection {
      bottom: 92px;
   }
}
@media only screen and (max-width: 656px) {
   .provider-selection {
      padding: 0 28px;
   }
   .promo-container-reg {
      display: none;
   }
}
div#pum_popup_title_2272 {
   text-align: left;
   margin-top: 18px;
   font-size: 1.8em;
}
.searchBoxCont {
   max-width: 1140px;
   margin: auto;
   text-align: center;
}
.provider-selection .owl-stage {
   margin: auto;
}
/*Verification*/
.formrow {
   display: flex;
   flex-flow: column;
}
.formrow label {
   font-size: 0.85em;
   color: #b3b3b3;
   padding-bottom: 5px;
   display: inline-block;
   margin-bottom: 0px;
   margin-top: 15px;
}
.formrow input {
   width: 100%;
   padding: 8px 10px;
   font-size: 0.8em;
   border: none;
   border-radius: 5px;
}
.formrow input#copyCurrentAddress {
   padding: unset;
   width: 18px;
   height: 17px;
   font-size: unset;
   color: #000;
   border-radius: 2px;
   border: solid 2px #717171;
}
.formrow input#copyCurrentAddress::after {
   left: 3px;
   top: -1px;
   border-color: #3b3b3b;
}
.formrow select {
   width: 100%;
   padding: 8px 10px;
   font-size: 0.8em;
   border: none;
   border-radius: 5px;
}
button#verifyBtn {
   width: 100%;
   background-image: radial-gradient(
      50% 100% at 50% 142%,
      #dd1212 0,
      #870404 100%
   );
   box-shadow: 0 15px 30px 0 #00000070, 0 0 10px 2px #ff000085;
   color: var(--color-white);
   border: none;
   font-size: 0.9em;
   font-weight: 800;
   display: inline-block;
   padding: 8px 0;
   text-decoration: unset;
   border-radius: 5px;
   letter-spacing: 0.1em;
   text-transform: uppercase;
   margin-top: 30px;
}
input#imgFront {
   background-image: radial-gradient(
      60% 100% at 50% 132%,
      #727272 0,
      #3e3e3e 100%
   );
   box-shadow: 0 0 5px 0 #202020, 0 0 5px 2px #2a2a2a;
   color: #ddd;
}
input#imgSelfie {
   background-image: radial-gradient(
      60% 100% at 50% 132%,
      #727272 0,
      #3e3e3e 100%
   );
   box-shadow: 0 0 5px 0 #202020, 0 0 5px 2px #2a2a2a;
   color: #ddd;
   display: block;
}
button#uploadPhotoBtn {
   width: 100%;
   background-image: radial-gradient(
      50% 100% at 50% 142%,
      #dd1212 0,
      #870404 100%
   );
   box-shadow: 0 15px 30px 0 #00000070, 0 0 10px 2px #ff000085;
   color: var(--color-white);
   border: none;
   font-size: 0.9em;
   font-weight: 800;
   display: inline-block;
   padding: 8px 0;
   text-decoration: unset;
   border-radius: 5px;
   letter-spacing: 0.1em;
   text-transform: uppercase;
   margin-top: 10px;
}
div#popmake-4154 {
   padding: 0;
}
div#popmake-4154 h3 {
   font-size: 1em;
   font-weight: 600;
   color: var(--color-white);
   letter-spacing: 0.1em;
}
form#uploadPhotoForm {
   top: 0px;
   width: 100%;
   height: 100%;
   display: flex;
   flex-flow: column;
   justify-content: center;
   background-size: cover;
   padding: 30px;
   border-radius: 14px;
}
form.verificationform {
   padding: 30px;
}
div#uploadPhotoMsg {
   color: #ffa600;
   font-size: 0.9em;
   padding-top: 18px;
}
.instruction {
   font-size: 0.8em;
   margin-top: 5px;
}
.welcome-user button {
   cursor: pointer;
   background-image: radial-gradient(
      50% 100% at 50% 142%,
      #dd1212 0,
      #870404 100%
   );
   box-shadow: 0 15px 30px 0 #00000070, 0 0 10px 2px #ff000085;
   color: var(--color-white);
   border: none;
   font-size: 0.8em;
   font-weight: 800;
   display: inline-block;
   padding: 6px 19px;
   text-decoration: unset;
   border-radius: 5px;
   letter-spacing: 0.1em;
   text-transform: uppercase;
   margin-top: 10px;
   margin-left: 14px;
}
/*endof Verification*/
.weeklygr8-btn {
   background-image: radial-gradient(
      50% 100% at 50% 142%,
      #dd1212 0,
      #870404 100%
   );
   box-shadow: 0 15px 30px 0 #00000070, 0 0 10px 2px #ff000080;
   text-shadow: 0 0 10px white;
   font-size: 0.6em;
   font-weight: 500;
   color: var(--color-white);
   margin-top: 25px;
   padding: 12px 35px;
   border-radius: 5px;
   transition: all 0.3s;
   text-transform: uppercase;
   text-decoration: none;
   margin-right: 15px;
}
.bal-subcontainer {
   position: absolute;
   top: 45px;
   left: -10%;
   background-image: radial-gradient(
      80% 82% at 50% -24%,
      #28282833 0,
      #04040475 100%
   );
   border-radius: 10px;
   border: solid 1px #3e3e3e14;
   box-shadow: 0 0 15px #1011129e;
   backdrop-filter: blur(10px);
   float: left;
   color: var(--color-white);
   font-size: 0.7em;
   padding: 20px;
   text-align: center;
}
.bal-subcontainer .red-button {
   background-image: radial-gradient(
      50% 100% at 50% 142%,
      #dd1212 0,
      #870404 100%
   );
   box-shadow: 0 0 5px 0 #4c0000, 0 0 10px 2px #a7000087;
   padding: 6px 18px;
   border-radius: 6px;
   color: var(--color-white);
   text-shadow: 0 0 5px var(--color-white);
   font-size: 0.9em;
   text-transform: uppercase;
   letter-spacing: 0.1em;
   cursor: pointer;
   transition: all 0.7s;
   display: block;
   margin: auto;
   border-width: 2px;
   margin-top: 0px;
}
@media only screen and (max-width: 656px) {
   .bal-subcontainer {
      top: 99px;
      left: 0%;
   }
}
.site-branding {
   transform: translate(130px, -12px);
}
.scrolled .site-branding {
   transform: scale(0.7) translate(120px, -10px);
}
@media only screen and (max-width: 856px) {
   .promotionSidebar .insideBox {
      z-index: -9;
      pointer-events: none;
   }
   .promotionSidebar:hover .insideBox {
      pointer-events: auto;
      z-index: 9;
   }
   .snow {
      display: none;
   }
   .site-branding {
      transform: translate(0px, -10px);
   }
   .scrolled .site-branding {
      transform: scale(0.7) translate(0px, -10px);
   }
}
.header-depositBTN {
   text-decoration: none;
   font-weight: 600;
   margin-left: 10px;
   color: var(--color-white);
   padding: 0 10px;
   border-radius: 5px;
   font-size: 1em;
   background-image: radial-gradient(
      50% 100% at 50% 142%,
      #dd1212 0,
      #870404 100%
   );
   box-shadow: 0 15px 30px 0 #00000070, 0 0 10px 2px #ff000080;
}
@media only screen and (max-width: 768px) {
   .header-depositBTN {
      display: inline-block !important;
      padding: 5px 10px !important;
   }
}
.main-banner-cta {
   width: 100%;
   position: absolute;
   bottom: 42px;
}
.main-banner-cta a {
   border: solid 2px;
   font-size: 20px;
   text-decoration: none;
   color: var(--color-white);
   padding: 10px 50px;
   border-radius: 50px;
   font-weight: 700;
}
.main-banner-cta .playnow {
   color: #930000;
   background-image: -webkit-linear-gradient(
      -20deg,
      rgb(233, 163, 19) 0%,
      rgb(244, 203, 89) 48%,
      rgb(255, 243, 159) 100%
   );
}
@media only screen and (max-width: 768px) {
   .main-banner-cta {
      width: 100%;
      bottom: 5px;
   }
   .main-banner-cta a {
      padding: 5px 30px;
      font-size: 0.8em;
      background-color: #910901;
   }
}
.popmake-5771 img {
   width: 40px;
   transform: scale(1.2);
   margin-right: 10px;
   transition: all ease 0.3s;
}
.popmake-5771 img:hover {
   transform: scale(1.3);
}
/*New CSS Style*/
.main-wrapper {
   display: flex;
   margin: 0;
}
.sticky-navigation {
   width: 100%;
   max-width: 227px;
   text-align: center;
   position: relative;
   height: 100%;
   overflow: auto;
}
.main-content-area {
   width: 100%;
   max-width: 1300px;
   padding-top: 10px;
   padding-bottom: 30px;
   margin: 0 auto;
   height: 100%;
}
.navigation-fixed-container {
   width: 227px;
   max-width: 252px;
   background: linear-gradient(
      180deg,
      rgb(16 16 73) 0%,
      rgb(5 10 87 / 30%) 36%,
      rgb(9 16 121) 100%
   );
   position: fixed;
   height: 100%;
   top: 0;
}
/*navigation CSS*/
.mobile-website-logo img{
  width: 80px;
}
.website-logo {
   margin-top: 20px;
   max-width: 145px;
}
.user-information {
   display: none;
   border-radius: 8px;
   background: #1730ad45;
   margin: 20px 20px 0;
   overflow: hidden;
}
.isLoggedIn .user-information {
   display: block;
}
.userwrapper {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
   gap: 2px;
   padding: 10px 0;
   line-height: 15px;
   height: 60px;
}
span.userID {
   color: var(--color-white);
   font-size: 13px;
   font-weight: 500;
}
span.userBalance {
   display: block;
   color: #f6dd01;
   font-size: 17px;
   font-weight: 700;
   width: 100%;
}
.c2a-depo-withdraw {
   display: flex;
   justify-content: center;
}
.c2a-depo-withdraw button {
   padding: 4px;
   background: red;
   font-size: 13px;
   font-weight: 700;
   text-transform: uppercase;
   width: 50%;
}
.depo-btn {
   background: radial-gradient(
      52.78% 52.78% at 50% 50%,
      #ffffff 0%,
      #f5dd00 100%
   ) !important;
   box-shadow: 0px 0px 3px #ffe600;
   border-radius: 0px 0px 0px 10px;
   color: #00053a !important;
   width: 100%;
   cursor: pointer;
   text-decoration: unset;
   font-weight: 600;
}
.withdraw-btn {
   background: radial-gradient(
      52.78% 52.78% at 50% 50%,
      #0a137a 0%,
      #00053a 100%
   ) !important;
   box-shadow: 0px 0px 3px #004987;
   border-radius: 0px 0px 10px 0px;
   color: #fff !important;
   width: 100%;
   cursor: pointer;
   text-decoration: unset;
}
/*navigation CSS*/
.navigation-menu-list {
   margin-top: 30px;
   cursor: pointer;
}
.menu a {
   height: 80px;
   color: var(--color-white);
   font-size: 14px;
   display: flex;
   align-items: center;
   padding: 0 40px;
   font-weight: 600;
   text-transform: uppercase;
   background-position: center;
   text-decoration: none;
}
.secondary-menu-list {
   text-align: left;
   padding: 20px;
   font-size: 14px;
   font-weight: 600;
   text-transform: uppercase;
   color: var(--color-white);
   gap: 10px;
   display: grid;
   margin-bottom: 20px;
}
.secondary-menu-list ul {
   list-style: none;
}
.secondary-menu-list li {
   border-bottom: 1px solid #28268f;
}
.secondary-menu-list ul a {
   display: block;
   color: var(--color-white);
   text-decoration: none;
   padding: 15px 0;
   padding-left: 20px;
}
.navarea-promotions {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   position: relative;
   align-items: flex-end;
}
.navarea-promotions img {
   width: 90%;
}
.navarea-promotions a {
   font-size: 12px;
   position: absolute;
   font-weight: 700;
   color: #f6dd01;
   bottom: -5%;
}
.divider-nav {
   width: 70%;
   height: 1px;
   border-top: 1px solid #300dbbcf;
   margin: auto;
   margin-bottom: 30px;
}
/*global css*/
.isLoggedIn .c2a-landingpage {
   display: none;
}
.c2a-landingpage {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   margin: 20px 20px 0;
}
.login-btn {
   background: radial-gradient(
      52.78% 52.78% at 50% 50%,
      #ffffff 0%,
      #f5dd00 100%
   ) !important;
   box-shadow: 0px 0px 3px #ffe600;
   border-radius: 15px 0 0 15px;
   color: #00053a !important;
   padding: 4px;
   font-size: 13px;
   font-weight: 700;
   text-transform: uppercase;
   width: 50%;
   cursor: pointer;
}
.reg-btn {
   background: radial-gradient(
      52.78% 52.78% at 50% 50%,
      #0a137a 0%,
      #00053a 100%
   ) !important;
   box-shadow: 0px 0px 3px #004987;
   border-radius: 0 15px 15px 0;
   color: #fff !important;
   padding: 4px;
   font-size: 13px;
   font-weight: 700;
   text-transform: uppercase;
   width: 50%;
   cursor: pointer;
}
#loading-overlay {
   display: none;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   z-index: 9999;
}
.loading-spinner {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   border: 4px solid rgba(255, 255, 255, 0.3);
   border-radius: 50%;
   border-top: 4px solid #ffffff;
   width: 40px;
   height: 40px;
   animation: spin 1s linear infinite;
}
@keyframes spin {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}
div#content {
   margin-left: 227px;
}
footer#colophon {
   margin-left: 227px;
}
.desktop-view {
   display: block;
}
.mobile-view {
   display: none;
}
.live-casino{
   max-width: 1300px;
   margin: 0 auto;
   display: flex;
   justify-content: space-around;
   padding-bottom: 50px;
}
/* Live Casino */
.liveCasino{
   max-width: 1300px;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   margin: 0 auto;

}
.hidden-xs{
   display: block!important;
}
.visible-xs{
   display: none!important;
}
.liveCasinoBtn{
   display: flex;
   justify-content: center;
   align-items: center;
}
.liveCasinoBtn div{
   margin-inline: 20px;
}

/* .liveContainer:hover {
   rotate: x 20deg;
   cursor: pointer;
 }
 .selection-box:hover::after {
   rotate: x calc(20deg * -1);
   translate: 0 60px;
 }
 .liveContainer:hover .liveChar {
   opacity: 1;
   transform: translateY(-2.5rem) translateZ(60px);
   rotate: x calc(20deg* -1);
}
.liveContainer{
   width: 350px;
   height: 450px;
   aspect-ratio: 9 / 16;
   position: relative;
   transform-style: preserve-3d;
}
.liveContainer::before{
   content: "";
   position: absolute;
   inset: 0;
   z-index: 100;
   background-image: url(https://joker96.net/wp-content/uploads/2023/09/bgbg.jpg);
   background-repeat: no-repeat;
   background-size: cover;
   opacity: 0;
}
.liveContainer .liveChar{
   position: absolute;
   scale: 1;
   opacity: 0;
   z-index: 100;
}
.liveContainer .charMain {
   position: absolute;
   z-index: 10;
   height: 100%;
   object-fit: cover;

}
.liveContainer::after {
   content: "";
   position: absolute;
   inset: 80% 0.5rem 0.5rem;
   translate: 0;
   transform: translateZ(-100px);
   background: black;
   filter: blur(1rem);
   z-index: 1;
   transition: rotate var(--timing), translate var(--timing);
} */
@media screen and (max-width: 840px) {
   .desktop-view{
      display: none !important;
   }
   .hidden-xs{
      display: none!important;
   }
   .visible-xs{
      display: block!important;
   }
   .promoBox{
      display: grid;
      grid-template-columns: 1fr 1fr;
   }
   .liveCasino{
      overflow-y: auto;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
   }
   .promoBox img{
      width: 100px;
      height: 100%;
      object-fit: cover;

   }
   .promotionContentarea{
      padding-inline: 20px;
   }
   div.mobile-view {
      display: block !important;
      padding-inline: 20px;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
   }
   .mobile-view section:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
   }
   .mobile-balance {
      color: var(--color-white);
      text-align: center;
      display: flex;
      gap: 10px;
   }
   .mobile-balance p {
      font-weight: bold;
      font-size: 10px;
      text-transform: uppercase;
   }
   .mobile-balance .balance {
      font-weight: bold;
      color: #f6dd01;
      font-size: 18px;
   }
   .mobile-balance div:nth-child(2) svg {
      background-color: #f6dd01;
      width: 20px;
      height: 20px;
      border-radius: 50%;
   }
   .mobile-balance div:nth-child(2) a {
      display: inline;
   }
   footer#colophon {
      margin-left: 0px;
   }
   div#content {
      margin-left: 0;
      background: linear-gradient(180deg, #070921 0%, #0f1558 100%);
   }
   .sg8-footer {
      background: #000225;
      padding: 30px 10px 20px !important;
      margin-top: 0px !important;
   }
  
   .text-content-title {
      font-size: 20px !important;
   }
   .text-content-area p {
      font-size: 14px !important;
      line-height: 25px !important;
   }
   .text-content-sub-title {
      font-size: 15px !important;
   }
   .payment-menthod-container {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start !important;
      gap: 10px;
   }
   .text-content-area {
      width: 100% !important;
      margin: auto;
   }
   .main-content-wrapper {
      padding: 24px 0 !important;
   }
   .page-headlines-container{
      margin-bottom: 5px!important;
   }
}
.text-content-title {
   color: var(--color-white);
   font-size: 30px;
   font-weight: 600;
   margin-bottom: 10px;
   text-transform: uppercase;
}
.text-content-sub-title {
   color: var(--color-white);
   font-size: 20px;
   font-weight: 600;
   margin-bottom: 10px;
   text-transform: uppercase;
}
.main-content-wrapper {
   background: #000225;
   margin: 0;
   padding: 50px 0;
   clear: both;
}
.text-content-area {
   max-width: 1300px;
   margin: 0 auto;
}
.mobile-container.mobile-view {
   position: fixed;
   width: 100%;
   z-index: 9999;
   bottom: 0;
}
.mobile-selector-option {
   display: grid;
   justify-content: center;
   text-align: center;
   width: 20%;
   border-top: 3px solid #f6dd01;
   background: linear-gradient(180deg, #070921 0%, rgb(5 10 87) 100%);
   box-shadow: 2px 0px 100px 20px rgb(0 0 0 / 67%);
   padding-bottom: 5px;
}
.mobile-selector-option a {
   display: grid;
}
.mobile-selector-option img {
   margin: auto;
   position: relative;
   top: -15px;
}
.mobile-selector-container {
   display: flex;
   justify-content: center;
   position: relative;
}
.mobile-selector-container a {
   text-decoration: none;
}
.mobile-selector-title {
   color: var(--color-white);
   text-transform: uppercase;
   font-weight: 700;
   font-size: 15px;
   line-height: 15px;
}
.mobile-selector-text {
   font-size: 13px;
   text-transform: uppercase;
   color: #f6dd01;
}
.mobile-slider {
   width: 100%;
   margin-left: 10px;
   border-radius: 10px;
   overflow: hidden;
}
.mobile-slider img {
   width: 98%;
   border-radius: 10px;
   margin-left: 57%;
   transform: translateX(-50%);
}
.mobile-slider-swipe {
   margin-bottom: 10px;
}
.mobile-main-wrapper {
   padding: 0;
   margin: 0;
   padding-top: 100px;
}
.mobile-slot-header {
   padding-left: 10px;
   display: flex;
   align-items: center;
   gap: 10px;
}
.mobile-text-title {
   line-height: 10px;
   margin-top: 20px;
}
.mobile-text-title span {
   font-size: 14px;
   color: #f6dd01;
   text-transform: uppercase;
   font-weight: 700;
}
.mobile-text-title p {
   font-size: 25px;
   font-weight: 700;
   text-transform: uppercase;
   color: var(--color-white);
}
.mobile-c2a-btn {
   top: 12px;
   position: relative;
}
.mobile-c2a-btn img {
   border-radius: 100%;
   padding: 5px;
   width: 70%;
   background: radial-gradient(
         52.78% 52.78% at 50% 50%,
         #ffffff 0%,
         #f5dd00 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
   box-shadow: 0px 0px 3px #ffe600;
}
.mobile-slots-selection {
   margin-top: 10px;
   padding-bottom: 20px;
}
.mobile-slots-selection img {
   width: 95%;
   margin-left: 30px;
}
.mobile-LD-selection {
   margin-top: 10px;
   padding-bottom: 20px;
}
.mobile-LD-selection img {
   width: 97%;
   margin-left: 42px;
}
.ld-page-selector {
   margin-left: 10px;
   margin-bottom: 20px;
}
.ld-page-selector img {
   width: 96%;
   margin-left: 34px;
   border-radius: 20px;
   overflow: hidden;
}
.games-container {
   width: 1300px;
   margin: 0 auto;
   overflow: hidden;
}
/*new css*/
.promotionSidebar {
   display: none;
}
iframe#NOVOCLOUDCCSPluginIFrame {
   display: none;
}
.header-news-logout {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 20px;
}


/*body-slots*/
.slots-headlines-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 20px;
   gap: 20px;
}
.slot-provider-list {
   display: flex;
   padding: 0 20px;
   background: #ffffff12;
   border-radius: 10px;
   overflow: hidden;
   width: 80%;
   justify-content: center;
}
.ld-provider-list {
   display: flex;
   overflow: hidden;
   width: 80%;
   gap: 10px;
}
.ld-provider-list img {
   width: unset;
}
.slot-provider-list img {
   object-fit: contain;
   width: 60%;
   height: 50px;
   padding: 0 20px;
}
.h2-text-style {
   line-height: 20px;
   width: 15%;
}
.h2-text-style span {
   text-transform: uppercase;
   color: #f6dd01;
   font-size: 13px;
   font-weight: 700;
   line-height: 13px;
}
.h2-text-style h2 {
   text-transform: uppercase;
   color: var(--color-white);
   font-weight: 700;
   font-size: 25px;
   line-height: 25px;
}
/*gamebox-slots*/
.game-box {
   position: relative;
   height: 200px;
   width: 200px;
   display: grid;
   align-content: center;
   justify-content: center;
   cursor: pointer;
}
.game-box::before {
   content: "";
   position: absolute;
   background: radial-gradient(circle, #00000000, #f6dd0159);
   width: 200px;
   height: 200px;
   opacity: 0;
   z-index: 1;
   border-radius: 20px;
}
.game-box:hover::before {
   opacity: 1;
   transition: all 500ms ease;
}
.game-box img {
   position: absolute;
}
.game-box button {
   position: relative;
   height: 40px;
   width: 130px;
   opacity: 0;
}
.game-box:hover button {
   z-index: 2;
   opacity: 1;
   transition: all 500ms ease;
}
.hottest-gamelist {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
   gap: 10px;
}
/*gamebox-ld*/
.game-box-ld {
   position: relative;
   height: 177px;
   width: 250px;
   display: grid;
   align-content: center;
   justify-content: center;
   cursor: pointer;
}
.game-box-ld::before {
   content: "";
   position: absolute;
   background: radial-gradient(circle, #00000000, #f6dd0159);
   width: 250px;
   height: 177px;
   opacity: 0;
   z-index: 1;
   border-radius: 20px;
}
.game-box-ld:hover::before {
   opacity: 1;
   transition: all 500ms ease;
}
.game-box-ld img {
   position: absolute;
}
.game-box-ld button {
   position: relative;
   height: 40px;
   width: 130px;
   opacity: 0;
}
.game-box-ld:hover button {
   z-index: 2;
   opacity: 1;
   transition: all 500ms ease;
}
.hottest-gamelist-ld {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
   gap: 10px;
}
/*selector-box*/
.services-selector-box {
   position: relative;
   height: 250px;
   width: 100%;
   display: grid;
   align-content: flex-end;
   justify-content: center;
   cursor: pointer;
}
.services-selector-box:hover img {
   border-bottom: 3px #f6dd01 solid;
   border-radius: 20px;
}
.services-selector-box img {
   position: absolute;
}
.services-selector-box a {
   position: relative;
   height: auto;
   padding: 10px;
   width: 130px;
   opacity: 0;
   margin: auto;
   text-align: center;
}
.services-selector-box:hover a {
   z-index: 2;
   opacity: 1;
   transform: translateY(-10px);
   transition: all 500ms ease;
}
.service-selector {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
   gap: 10px;
}
.service-selector span {
   z-index: 1;
   font-size: 25px;
   font-weight: 700;
   text-transform: uppercase;
   color: var(--color-white);
   position: relative;
   top: -10px;
}
.featured-slots-container {
   margin-bottom: 20px;
}
.text-content-area h1 {
   color: var(--color-white);
   font-size: 30px;
   font-weight: 600;
   margin-bottom: 10px;
}
.text-content-area h2 {
   color: var(--color-white);
   font-size: 20px;
   font-weight: 600;
   margin-bottom: 10px;
}
.text-content-area p {
   color: var(--color-white);
   font-size: 14px;
   font-weight: normal;
   line-height: 30px;
   margin-bottom: 10px;
}
.logout-BTN img {
   width: 16px;
   height: 16px;
}
.logout-BTN a {
   color: var(--color-white);
   text-transform: uppercase;
   text-decoration: none;
   font-size: 15px;
   margin-left: 5px;
   display: flex;
   align-items: center;
   gap: 10px;
   cursor: pointer;
}
.logout-BTN a:hover {
   color: #fff67d;
}
.payment-menthod-container {
   display: flex;
   align-items: center;
   gap: 10px;
}
.payment-menthod-container img {
   height: 100%;
}
/* Arrow button customization */
.slick-prev,
.slick-next {
   background-color: #ddd;
   /* Light grey background */
   color: #333;
   /* Dark text color */
   padding: 10px;
   border-radius: 50%;
}
/* Navigation dots customization */
.slick-dots li button:before {
   color: #333;
   /* Dot color */
}
.slick-dots li.slick-active button:before {
   color: #000;
   /* Active dot color */
}
.slots-prev,
.slots-next {
   display: block !important;
}
.sg8-footer {
   background: #000225;
   padding-top: 50px;
   margin-top: 0px !important;
}
.sg8-footer-main {
   display: flex;
   margin: auto;
   justify-content: space-between;
   max-width: 1300px;
   margin-bottom: 50px;
   padding-top: 40px;
}
.footer-flexbox h4 {
   color: var(--color-white);
   font-size: 18px;
   margin-bottom: 15px;
}
.footer-links {
   padding: 0px;
   margin: 0px;
   background: transparent;
}
.footer-links a {
   color: var(--color-white);
   font-size: 14px;
   text-decoration: none;
}
.footer-links a:hover {
   color: var(--color-white);
}
.footer-links li {
   text-decoration: none;
   list-style: none;
   margin-bottom: 10px;
}
.sg8-footer-bottom {
   max-width: 1300px;
   margin: auto;
   padding-bottom: 20px;
}
.tagline-footer p {
   color: #bbbbbb;
   font-size: 13px;
}
.tagline-footer a {
   color: #bbbbbb;
   text-decoration: none;
}
.footer-icons {
   display: flex;
   justify-content: space-between;
   margin-top: 10px;
   gap: 10px;
}
.footer-icons a {
   margin-right: 5px;
}
.footer-icons img {
   width: 30px;
   height: 30px;
}
.footer-c1 {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
   gap: 50px;
   width: 100%;
}
.footer-c2 {
   display: flex;
   justify-content: space-evenly;
   width: 100%;
}
.footer-headline-text {
   font-size: 18px;
   color: var(--color-white);
   text-transform: uppercase;
   font-weight: 600;
   margin-bottom: 20px;
}
.footer-text {
   font-size: 14px;
   color: var(--color-white);
   font-weight: normal;
}
.quick-links-container {
   display: flex;
   gap: 30px;
}
.contact-section a img {
   width: unset;
   margin: 0 6px;
}
table {
   width: 100%;
   margin-block: 20px;
   border-collapse: collapse;
}
thead th {
   box-shadow: 0 0 5px #00000040;
   color: var(--color-white);
   font-size: 15px;
   font-weight: 700;
   padding-block: 10px;
}

.table-main td {
   text-align: center;
   background-color: #bbbbbb0e;
   border: 1px solid #ffffff4d;
   color: var(--color-white);
   box-shadow: 0 0 5px #00000040;
   font-size: 13px;
   padding-block: 20px;
}
.table-main table thead {
   background-image: radial-gradient(
      80% 82% at 50% -24%,
      #28282833 0,
      #04040475 100%
   );
}
/* Promotion */
.promotionContentarea {
   width: 100%;
   max-width: 1300px;
   margin: 0 auto;
}
.promotionWrapper {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
   gap: 35px;
   margin: 0 auto;
}
.promotionWrapper li {
   text-align: center;
}
.promotionBox {
   /* border-radius: 20px;
     */
   /* width: 300px;
     */
   overflow: hidden;
   text-align: center;
   box-shadow: 0px 7px 30px rgb(0 0 0 / 50%);

}
.promotionBox:hover {
   transform: scale(1.1);
   transition: transform 0.2s;
   /* Animation */
}
.promotionWrapper img {
   width: 100%;
   box-shadow: 6px 0px 20px rgb(0 0 0 / 70%);
   z-index: 1;
   /* max-width: 247px;
     */
}
.promotionContent {
   width: 100%;
   /* height: 200px;
     background-color: #ffffff;
     */
   padding: 20px;
   background: rgba(255, 255, 255, 0.25);
   border-bottom-left-radius: 20px;
   border-bottom-right-radius: 20px;
}

.promoBox img {
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;

}

.promotionSidebar {
   display: none;
}
iframe#NOVOCLOUDCCSPluginIFrame {
   display: none;
}
.yellow-btn {
   display: inline-block;
   text-align: center;
   background: radial-gradient(
      52.78% 52.78% at 50% 50%,
      #ffffff 0%,
      #f5dd00 100%
   ) !important;
   box-shadow: 0px 0px 3px #ffe600;
   color: #00053a !important;
   text-transform: uppercase;
   font-size: 14px;
   font-weight: 700;
   padding: 10px 0;
   border-radius: 50px;
   width: 150px;
   text-decoration: none;
   transform: scale(1);
}
.yellow-btn:hover {
   transform: scale(1.1);
   transition: transform 0.2s;
   /* Animation */
}
.text-content-area h1 {
   color: var(--color-white);
   font-size: 30px;
   font-weight: 600;
   margin-bottom: 10px;
}
.text-content-area h2 {
   color: var(--color-white);
   font-size: 20px;
   font-weight: 600;
   margin-bottom: 10px;
}
.text-content-area p {
   color: var(--color-white);
   font-size: 14px;
   font-weight: normal;
   line-height: 30px;
   margin-bottom: 10px;
}
.logout-BTN img {
   width: 16px;
   height: 16px;
}
.logout-BTN a {
   color: var(--color-white);
   text-transform: uppercase;
   text-decoration: none;
   font-size: 15px;
   margin-left: 5px;
}
.payment-menthod-container {
   display: flex;
   align-items: center;
   gap: 10px;
}
.payment-menthod-container img {
   height: 100%;
}
/*popup*/
.overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   /* background: linear-gradient( rgb(0 0 0 / 0%), rgb(255 255 255 / 0%), rgb(255 255 255) );
     */
}
.fade-in {
   animation: fadeIn ease 0.5s;
   -webkit-animation: fadeIn ease 0.5s;
   -moz-animation: fadeIn ease 0.5s;
   -o-animation: fadeIn ease 0.5s;
   -ms-animation: fadeIn ease 0.5s;
}
@keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}
@-moz-keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}
@-webkit-keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}
@-o-keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}
@-ms-keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}
.close-btn {
   color: #000000;
   padding: 5px 55px;
   border: 1px solid #000000;
   border-radius: 20px;
   text-transform: uppercase;
   font-weight: 600;
   font-size: 0.9rem;
   cursor: pointer;
   transition: all 200ms ease;
}
.titlePromotion {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
/* .close-btn:hover {
     font-size: 1.1em;
}
 */
/* scrollbar */
.tncBox::-webkit-scrollbar {
   display: none;
}
.tncContentSlide h2,
h3,
h4,
h5 {
   font-family: "Oswald", sans-serif;
}
.closeBTNWrapper {
   max-width: 1000px;
   width: 100%;
   margin: auto;
   display: flex;
   justify-content: flex-end;
}
.x-btn {
   background-color: #891313;
   border-radius: 5px;
   border: solid 1px #e44c4c;
   box-shadow: 0 0 7px #ff3e3e, inset 0 0 7px #ff3e3e;
   padding: 10px;
   color: var(--color-white);
   top: 30px;
   position: relative;
   right: -10px;
   z-index: 100;
   font-size: 1rem;
}
.tncWrapper {
   background-color: #000000b8;
   backdrop-filter: blur(10px);
   display: none;
   position: fixed;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   padding: 2% 15px;
   z-index: 9999999;
   animation: slide 1s;
   transition: all 200ms ease;
   margin-top: 0;
}
.tncBox {
   background-color: var(--color-white);
   width: 100%;
   max-width: 1000px;
   margin: auto;
   overflow: auto;
   height: 100%;
   border-radius: 20px;
   box-shadow: 0 0 50px 10px rgb(255 255 255 / 29%);
   border: 2px solid var(--color-white);
}
.tncBanner {
   position: relative;
   text-align: center;
   color: white;
}
.tncBanner img {
   display: block;
   width: 100%;
   height: auto;
}
.tncContent {
   background-color: var(--color-white);
   color: #000;
   padding: 30px 50px;
   animation: fades 1s;
   transition: all 200ms ease;
   margin-bottom: 60px;
}
.tncContent h2 {
   font-size: 2rem;
   font-weight: 700;
   text-transform: uppercase;
   margin: 10px 0px;
}
.tncContent h2::after {
   content: "";
   display: block;
   width: 150px;
   height: 5px;
   background-color: #0000002b;
}
.tncContent .title {
   font-size: 1.5em;
   font-weight: 700;
   text-transform: uppercase;
   margin: 10px 0px;
}
.tncContent p {
   margin: 10px 0px;
   font-size: 1em;
   line-height: 2em;
}
.tncContent ol {
   padding: 0px;
   margin: 20px;
}
.tncContent li {
   font-size: 1rem;
   line-height: 2em;
}
.tncContent span {
   font-weight: 600;
   display: block;
}
.tncContent table {
   width: 100%;
   border-radius: 10px;
   overflow: hidden;
   text-align: center;
   border-collapse: collapse;
   margin: 10px 0px;
}
.tncContent th {
   background-color: #24292c;
   padding: 10px;
   color: var(--color-white);
   font-weight: 600;
   box-shadow: inset 0 0 2px #0000002b;
}
.tncContent td {
   background-color: #595959;
   padding: 10px;
   font-size: 0.9rem;
   color: var(--color-white);
   box-shadow: inset 0 0 2px #0000002b;
}
/*endpopup*/
.promotionSidebar {
   display: none;
}
iframe#NOVOCLOUDCCSPluginIFrame {
   display: none;
}
.header-news-logout {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 20px;
}

.news-heading {
   padding: 0 10px 0 0;
}
.news-container .news {
   position: relative;
   width: 100%;
}
/*body-slots*/
.slots-headlines-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 20px;
   gap: 20px;
}
.slot-provider-list {
   display: flex;
   padding: 0 20px;
   background: #ffffff12;
   border-radius: 10px;
   overflow: hidden;
   width: 80%;
   justify-content: center;
}
.ld-provider-list {
   display: flex;
   overflow: hidden;
   width: 80%;
   gap: 10px;
}
.slot-provider-list img {
   object-fit: contain;
   width: 60%;
   height: 50px;
   padding: 0 20px;
}
.page-headlines-container {
   display: grid;
   text-align: center;
   gap: 10px;
   padding: 20px 0;
   margin-bottom: 25px;
}
.page-h2-text-style span {
   color: #f6dd01;
}
.page-h2-text-style h1 {
   color: var(--color-white);
   font-weight: 400;
   text-transform: uppercase;
}
.ld-game-selector {
   display: flex;
   justify-content: center;
   gap: 20px;
   padding-bottom: 40px;
}
/* mobile btn */
.mobile-login-reg a{
   display: inline-block;
   padding-inline: 20px;
   padding-block: 5px;
   width: 100%;
   border-radius: 10px;
}
/*gamebox-slots*/
.ld-game-box {
   position: relative;
   height: 400px;
   width: 300px;
   display: grid;
   align-content: center;
   justify-content: center;
   cursor: pointer;
   overflow: hidden;
   border-radius: 20px;
}
.ld-game-box::before {
   content: "";
   position: absolute;
   background: radial-gradient(circle, #00000000, #f6dd0159);
   width: 300px;
   height: 400px;
   opacity: 0;
   z-index: 1;
   border-radius: 20px;
}
.ld-game-box:hover::before {
   opacity: 1;
   transition: all 500ms ease;
}
.ld-game-box img {
   position: absolute;
   height: 100%;
}
.ld-game-box button {
   position: relative;
   height: 40px;
   width: 130px;
   opacity: 0;
}
.ld-game-box:hover button {
   z-index: 2;
   opacity: 1;
   transition: all 500ms ease;
}
.text-content-area h1 {
   color: var(--color-white);
   font-size: 30px;
   font-weight: 600;
   margin-bottom: 10px;
}
.text-content-area h2 {
   color: var(--color-white);
   font-size: 20px;
   font-weight: 600;
   margin-bottom: 10px;
}
.text-content-area p {
   color: var(--color-white);
   font-size: 14px;
   font-weight: normal;
   line-height: 30px;
   margin-bottom: 10px;
}
.logout-BTN img {
   width: 16px;
   height: 16px;
}
.logout-BTN a {
   color: var(--color-white);
   text-transform: uppercase;
   text-decoration: none;
   font-size: 15px;
   margin-left: 5px;
}
.payment-menthod-container {
   display: flex;
   align-items: center;
   gap: 10px;
}
.payment-menthod-container img {
   height: 100%;
}
/*Logout*/
.header-news-logout {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 20px;
}

.news-heading {
   padding: 0 10px 0 0;
}
.news-container .news {
   position: relative;
   width: 100%;
}
.promotion-container {
   height: 0;
}
.promotion-title {
   font-weight: 700;
   font-size: 18px;
   color: var(--color-white);
}
.promotion-info {
   font-size: 15px;
   height: 35px;
   line-height: 20px;
   color: var(--color-white);
}
.promotionSidebar {
   display: none;
}
iframe#NOVOCLOUDCCSPluginIFrame {
   display: none;
}
.header-news-logout {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 20px;
}
.schedule-container {
   font-size: 16px;
   background: #ffffff12;
   color: #73c2fb;
   padding: 10px 32px;
   display: flex;
   overflow: hidden;
   border-radius: 10px;
   width: 90%;
}
.schedule-heading {
   padding: 0 10px 0 0;
}
.schedule-container .schedule {
   position: relative;
   width: 70%;
}
.schedule-container .schedule-single {
   color: var(--color-white);
   text-decoration: none;
   font-weight: normal;
   opacity: 0;
   position: absolute;
   top: 0;
   left: 0;
   transform: translateY(-8px);
   transition: all 400ms ease;
   pointer-events: none;
}
.schedule-container .schedule-single.active {
   opacity: 1;
   transform: translateY(0);
   pointer-events: auto;
}
/*body-slots*/
.slots-headlines-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 20px;
   gap: 20px;
}
.slot-provider-list {
   display: flex;
   padding: 0 20px;
   background: #ffffff12;
   border-radius: 10px;
   overflow: hidden;
   width: 80%;
   justify-content: center;
}
.ld-provider-list {
   display: flex;
   overflow: hidden;
   width: 80%;
   gap: 10px;
}
.slot-provider-list img {
   object-fit: contain;
   width: 60%;
   height: 50px;
   padding: 0 20px;
}
.ld-game-selector {
   display: flex;
   justify-content: center;
   gap: 20px;
}
/*gamebox-slots*/
.ld-game-box {
   position: relative;
   height: 400px;
   width: 300px;
   display: grid;
   align-content: center;
   justify-content: center;
   cursor: pointer;
   overflow: hidden;
   border-radius: 20px;
}
.ld-game-box::before {
   content: "";
   position: absolute;
   background: radial-gradient(circle, #00000000, #f6dd0159);
   width: 300px;
   height: 400px;
   opacity: 0;
   z-index: 1;
   border-radius: 20px;
}
.ld-game-box:hover::before {
   opacity: 1;
   transition: all 500ms ease;
}
.ld-game-box img {
   position: absolute;
   height: 100%;
}
.ld-game-box button {
   position: relative;
   height: 40px;
   width: 130px;
   opacity: 0;
}
.ld-game-box:hover button {
   z-index: 2;
   opacity: 1;
   transition: all 500ms ease;
}
.text-content-area h1 {
   color: var(--color-white);
   font-size: 30px;
   font-weight: 600;
   margin-bottom: 10px;
}
.text-content-area h2 {
   color: var(--color-white);
   font-size: 20px;
   font-weight: 600;
   margin-bottom: 10px;
}
.text-content-area p {
   color: var(--color-white);
   font-size: 14px;
   font-weight: normal;
   line-height: 30px;
   margin-bottom: 10px;
}
.logout-BTN img {
   width: 16px;
   height: 16px;
}
.logout-BTN a {
   color: var(--color-white);
   text-transform: uppercase;
   text-decoration: none;
   font-size: 15px;
   margin-left: 5px;
}
.payment-menthod-container {
   display: flex;
   align-items: center;
   gap: 10px;
}
.payment-menthod-container img {
   height: 100%;
}
.gameIframe {
   width: 100%;
   max-width: 1280px;
   height: 700px;
   display: inline-block;
   border: none;
   margin-top: 10px;
   border-radius: 10px;
}
.pageCont {
   text-align: center;
}
.game {
   position: relative;
   max-width: 500px;
   border-radius: 15px;
   border: solid 1px #ffffff14;
   box-shadow: 0 0 15px #10111259;
}
.pageCont {
   background-attachment: fixed;
   padding: 60px 15px;
   padding-top: 120px;
}
.sb-cont {
   padding-left: 0px;
   padding-right: 0px;
}
.banner-continer {
   margin-bottom: 60px;
}
.sports-desktop-selector {
   max-width: 1300px;
   margin: auto;
   display: flex;
   text-align: center;
}
.iframe-selector {
   width: 100%;
   display: flex;
   background-color: #b5b5b5;
   border-radius: 5px;
   overflow: hidden;
   margin: 0px;
   box-shadow: 0 0 5px 0px #262626;
}
.iframe-selector .selection {
   width: 100%;
   border-width: 1px;
   font-size: 1em;
   box-shadow: 0 0 2px #0000004f;
   background-image: radial-gradient(
      50% 100% at 50% 142%,
      var(--color-white) 0,
      #ffffff00 100%
   );
}
.iframe-selector .selection > button {
   width: 100%;
   color: #000 !important;
   padding: 5px;
   background-color: transparent !important;
}
.window-selector {
   width: 100%;
   display: flex;
   padding: 0px 5px;
   margin-top: 5px;
}
.window-selector .selection {
   width: 100%;
   display: block;
}
.window-selector button {
   padding: 6px 15px;
   width: 100%;
   max-width: 370px;
   font-size: 0.8em;
   background-color: transparent !important;
   background-image: radial-gradient(
      60% 100% at 50% 132%,
      #ffffff36 0,
      #2e2e2e 100%
   );
   box-shadow: 0 0 5px 0 #202020, 0 0 5px 2px #00000026,
      inset 0 0 2px 2px #97979729;
   border: solid 1px #424242;
   border-radius: 5px;
   color: #d3d3d3 !important;
}
.iframe-selector .selection.active {
   background-image: radial-gradient(
      50% 100% at 50% 142%,
      #ef5555 0,
      #890909 100%
   );
   box-shadow: 0 0 2px #0000004f, inset 0 0 10px #cb4949;
}
.iframe-selector .selection.active button {
   color: var(--color-white) !important;
}
.sports-mobile-selection {
   display: flex;
}
.account-container h2 {
   margin-top: 30px;
   margin-bottom: 15px;
}
.from-to-type-container button {
   width: unset;
   line-height: 0em;
   padding: 15px;
}
.vip-content-area {
   padding: 30px 15px;
}
.vip-content {
   background-image: radial-gradient(
      52% 65% at 50% 50%,
      #ffffff0a 0,
      #ffffff0a 100%
   );
   border-radius: 10px;
   border: solid 1px #ffffff14;
   box-shadow: 0 0 15px #10111259;
   backdrop-filter: blur(10px);
   color: var(--color-white);
   padding: 50px 30px;
   max-width: 1020px;
   margin: auto;
}
.vip-collapsible > li {
   list-style: none;
   position: relative;
}
.vip-collapsible h3 {
   font-size: 1.2em;
   margin: auto;
   padding: 30px 0px;
   padding-left: 30px;
   cursor: pointer;
   position: relative;
   text-transform: uppercase;
}
.vip-collapsible > li .collapse-content {
   margin: 0px 60px;
   overflow: hidden;
   transition: all 0.5s;
}
.vip-collapsible > li.active .collapse-content {
   max-height: 1000px !important;
}
.collapse-content table {
   box-shadow: 0 8px 5px #0000002b;
   border: solid 1px #ffffff14;
   background-color: #00000026;
   font-size: 0.9em;
   text-align: center;
   margin-bottom: 15px;
   width: 100%;
}
.collapse-content table tr:nth-child(1) th {
   background-image: radial-gradient(
      80% 82% at 50% -24%,
      #28282833 0,
      #04040475 100%
   );
   text-align: center;
   font-weight: 400;
   padding: 8px;
}
.collapse-content table tr:nth-child(2) th {
   text-transform: uppercase;
   color: #9d9d9d;
   font-size: 0.9em;
   letter-spacing: 0.1em;
   padding: 8px;
}
.collapse-content table td:nth-child(1) {
   text-align: center;
   color: #9d9d9d;
}
.collapse-content table td {
   padding: 8px;
}
.vip-collapsible > li .collapse-content ol {
   list-style: decimal;
   padding-left: 15px;
}
.vip-collapsible > li .collapse-content ol li {
   padding: 5px;
   padding-left: 10px;
   font-size: 0.9em;
}
.benefits-container {
   width: 100%;
   margin: auto;
}
.benefits-container h2 {
   font-size: 1.5em;
   font-weight: 600;
   color: var(--color-white);
}
.title-line {
   height: 3px;
   background-color: #ea7373;
   border: solid 1px #e04343;
   border-radius: 2px;
   box-shadow: 0 0 6px #f50000;
   width: 60px;
   margin: auto;
   margin-top: 18px;
}
.benefit-slider .table-container {
   border-radius: 10px;
   backdrop-filter: blur(10px);
   color: #000;
   padding: 30px;
   font-size: 1em;
   clear: both;
   margin-top: 45px;
   position: relative;
}
.benefit-slider .benefits-bronze > .table-container {
   background-image: radial-gradient(
      100% 138% at 50% 88%,
      var(--color-white) 0,
      #ffffffde 100%
   );
   border: solid 1px #ffffff14;
   box-shadow: 0 0 15px #10111299;
}
.benefit-slider .benefits-silver > .table-container {
   background-image: radial-gradient(
      100% 138% at 50% 88%,
      var(--color-white) 0,
      #ffffffde 100%
   );
   border: solid 1px #ffffff14;
   box-shadow: 0 0 15px #10111299;
}
.benefit-slider .benefits-gold > .table-container {
   background-image: radial-gradient(
      100% 138% at 50% 88%,
      var(--color-white) 0,
      #ffffffde 100%
   );
   border: solid 1px #ffffff14;
   box-shadow: 0 0 15px #10111299;
}
.benefit-slider .benefits-platinum > .table-container {
   background-image: radial-gradient(
      100% 138% at 50% 88%,
      var(--color-white) 0,
      #ffffffde 100%
   );
   border: solid 1px #ffffff14;
   box-shadow: 0 0 15px #10111299;
}
.benefit-slider .table-container:before {
   content: "";
   position: absolute;
   display: block;
   width: 100%;
   height: 100%;
   border-radius: 10px;
   z-index: 9;
   pointer-events: none;
   top: 0px;
   left: 0;
}
.benefit-slider .benefits-bronze > .table-container:before {
   border: solid 3px #f9e1bc;
   box-shadow: 0 0 26px #ffa500, 0 0 10px 2px #fcc869, inset 0 0 26px #ffb37d;
}
.benefit-slider .benefits-silver > .table-container:before {
   border: solid 3px #e7e7e7;
   box-shadow: 0 0 26px #bbc1cb, 0 0 10px 2px #e2e4e7, inset 0 0 26px #ced0d7;
}
.benefit-slider .benefits-gold > .table-container:before {
   border: solid 3px #f2f9bc;
   box-shadow: 0 0 26px #fff800, 0 0 10px 2px #f0fc69, inset 0 0 26px #fff67d;
}
.benefit-slider .benefits-platinum > .table-container:before {
   border: solid 3px #edeafd;
   box-shadow: 0 0 26px #bbaafd, 0 0 10px 2px #c3c2cb, inset 0 0 26px #a9a5bd;
}
.benefit-slider > div h4 {
   font-size: 1em;
   padding: 15px 0px;
   color: #000;
}
.benefit-slider > div th {
   font-weight: 400;
   color: #000000;
}
.benefit-slider > div th,
.benefit-slider > div th {
   padding: 0px;
}
.benefit-slider > div td {
   text-align: right;
   font-weight: 700;
   position: relative;
}
.benefit-slider > div td img {
   right: 0px;
   max-width: 44px;
}
.divider-1 {
   width: 100%;
   height: 1px;
   margin: 10px auto;
   background-color: #6a6a6a;
}
.benefit-slider > div h4 {
   font-size: 1em;
   padding: 0px;
   color: #000;
}
.benefits-container table {
   width: 100%;
   font-size: 0.9em;
}
.vip-slides-continer {
   display: flex;
   max-width: 1020px;
   width: 100%;
   margin: auto;
   gap: 20px;
   padding-top: 50px;
}
.vip-selection {
   background-color: #00000070;
   padding: 10px;
   border-radius: 15px;
   display: flex;
   flex-flow: column;
   justify-content: center;
}
.vip-selection-item {
   cursor: pointer;
}
.vip-selection img {
   max-width: 100px;
}
.vip-selection img.active {
   filter: drop-shadow(0 0 15px #fff);
}
.formlogo {
   max-width: 200px;
}
.promotionPage {
   max-width: 1300px;
   margin: 0 auto;
   color: white;
   padding-bottom: 80px;
   padding-inline: 20px;
}
.promotionPage p {
   padding-block: 5px;
}
.promotionPage img {
   border-radius: 15px;
}
.promotionPage h1 {
   padding-top: 20px;
   font-size: clamp(2rem, 2vw, 2rem);
}
.promotionPage h1::after {
   content: "";
   display: block;
   width: 150px;
   height: 5px;
   background-color: #e7f33e;
}
.promotionPage h2 {
   padding-bottom: 8px;
}
.promotionPage ol li {
   padding-block: 5px;
   list-style-type: decimal;
   margin-left: 35px;
}
.modal {
   justify-content: center;
   align-items: center;
   position: fixed;
   z-index: 9;
   height: 100%;
   width: 100%;
   background-color: #060606d6;
   backdrop-filter: blur(5px);
}
#LoginTrigger {
   display: none;
}
#LoginTrigger.show {
   display: flex;
}
#LoginTrigger .modal-container {
   text-align: center;
   width: 100%;
   /* max-width: 250px; */
}
.modal-login{
   display: flex;
}

.modal-login  > div{
   color: #fff;
}
#RegTrigger {
   display: none;
}
#RegTrigger.show {
   display: flex;
}
.modal-main-container {
   position: relative;
   border: solid 1px #292929;
   border-radius: 10px;
   box-shadow: 0 0 24px #000;
}
.modal-banner{
   width: 100%;
   display: block;
}

.slick-prev,  .slick-next{
   display: none!important;
}

#LoginTrigger .modal-main-container {
   width: 100%;
   max-width: 532px;
}
#RegTrigger .modal-container {
   text-align: center;
   width: 100%;
   max-width: 250px;
}
/* Promotion Mobile */
.formcol {
   display: flex;
   gap: 6px;
}
.formcol div {
   width: 100%;
}
.formlogo {
   max-width: 156px;
   margin: auto;
}
.form-login {
   display: flex;
   flex-flow: column;
   gap: 4px;
}
.form-login input {
   padding: 8px;
   border-radius: 5px;
   border: none;
   width: 100%;
   height: 32px;
}
.form-login button {
   background: radial-gradient(
      52.78% 52.78% at 50% 50%,
      #ffffff 0%,
      #f5dd00 100%
   ) !important;
   box-shadow: 0px 0px 3px #ffe600;
   color: #00053a !important;
   text-transform: uppercase;
   font-size: 14px;
   font-weight: 700;
   padding: 7px 0;
   border-radius: 5px;
   margin-top: 16px;
}
.modal-close {
   background: unset;
   background-color: #696969;
   border: solid 1px #575757;
   box-shadow: 0 0 10px #535353;
   line-height: 1em;
   color: #ffffff;
   font-size: 1.2em;
   position: absolute;
   right: 10px;
   top: 10px;
   border-radius: 12px;
   cursor: pointer;
   height: 32px;
   width: 32px;
   font-weight: bold;
}
.form-register {
   display: flex;
   flex-flow: column;
   gap: 4px;
   padding: 16px;
   max-width: 445px;
}
.form-register input {
   padding: 8px;
   border-radius: 5px;
   border: none;
   width: 100%;
   height: 32px;
}

.form-register select {
  padding: 8px;
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 32px;
}
.HaveAccount a.login {
   background: radial-gradient(52.78% 52.78% at 50% 50%, #ffffff 0%, #f5dd00 100%) !important;
   box-shadow: 0px 0px 3px #ffe600;
   color: #000 !important;
}
.form-register button {
   background: radial-gradient(
      52.78% 52.78% at 50% 50%,
      #2936cf 0%,
      #00053a 100%
   ) !important;
   box-shadow: 0px 0px 8px #000000;
   border: solid 1px #0a127b;
   border-radius: 5px;
   color: #fff !important;
   padding: 10px;
   font-size: 13px;
   font-weight: 700;
   text-transform: uppercase;
   cursor: pointer;
   margin-top: 16px;
}
.form-login label,
.form-register label {
   color: #cdcdcd;
   font-size: 0.9em;
   text-align: left;
   margin-top: 12px;
   display: block;
}
input[type="checkbox"] {
   width: unset;
   height: unset;
   margin-right: 10px;
}

.HaveAccount {
   color: #999999;
   display: flex;
   text-align: center;
   margin-top: 15px;
   flex-flow: column;
   position: absolute;
   width: 100%;
}
 
 .HaveAccount a {
   background: radial-gradient(52.78% 52.78% at 50% 50%, #0a137a 0%, #00053a 100%) !important;
   box-shadow: 0px 0px 3px #004987;
   border-radius: 15px;
   color: #fff !important;
   padding: 4px;
   font-size: 14px;
   font-weight: 700;
   text-transform: uppercase;
   width: 100%;
   max-width: 236px;
   margin: auto;
   margin-top: 10px;
   cursor: pointer;
}

@media (max-width: 860px) {
   .modal-banner{
      display: none;
      margin-inline: 20px;
   }
   .modal-main-container{
      margin-inline: 20px;
   }
}
@media (min-width: 857px) {
   .main-wrapper {
      padding-inline: 20px;
   }

   .main-content-wrapper,
   .sg8-footer-main,
   .sg8-footer-bottom {
      padding-inline: 20px;
   }
   
}
@media only screen and (max-width: 856px) {
  #masthead{
    position: sticky;
    top: 0;
    background: #000225;
    z-index: 99999;
   }
   .news-container{
    margin-inline: 20px;
   }
   .promotionPage h1 {
      font-size: 22px;
      line-height: 1.7;
   }
   .promotionPage h1::after {
      background: none;
   }
   .game {
      width: 100% !important;
      display: inline-block !important;
   }
   .promotionBannerMobile {
      display: block;
   }
   .promotionBanner {
      display: none;
   }
   .promotionObjectFit {
      overflow: hidden;
      border-radius: 20px;
   }
   .promotionObjectFit img {
      width: 650px;
      height: 280px;
      object-fit: cover;
      object-position: 95% 10%;
   }
}
@media screen and (max-width: 840px) {
   .mobileFooterNav {
      display: block;
   }

   .promotionBox {
      border-radius: 0;
      width: 100%;
      overflow: hidden;
      text-align: left;
      display: flex;
      background: #ffffff;
      align-items: center;
      position: relative;
      padding-left: 10px;
      height: 100px;
   }
   .promotionContent {
      width: 100%;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
   }
   .promotion-title {
      font-weight: 700;
   }
   .promotion-info {
      height: auto;
      line-height: 20px;
      padding-block: 5px;
   }
   .promolink-container a {
      color: #000;
      text-decoration: none;
      font-size: 11px;
      border-radius: 50px;
      padding-top: 5px;
   }
   .promotionWrapper img {
      position: relative;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      box-shadow: none !important;
   }
   .promotionWrapper {
      display: grid;
      justify-content: center;
      gap: 15px;
      margin-inline: 10px;
   }
   .promotionWrapper li {
      margin-bottom: 25px;
   }
}

.slot-provider-container {
   background: linear-gradient(
      90deg,
      rgba(45, 48, 74, 0) 0%,
      #35395e 50.5%,
      rgba(106, 113, 176, 0) 100%
   );
}
.provider-list-view-all-btn {
   text-align: center;
}
.slot-filter-search-wrapper {
   display: flex;
   align-items: center;
   position: relative;
}
select#myDropdown {
   background: none;
   border-radius: 50px;
   height: 35px;
   padding: 0px 20px;
   line-height: 20px;
   font-size: 18px;
   color: var(--color-white);
   text-transform: uppercase;
}
.filter-slots {
   position: absolute;
   right: 0;
}
.provider-logo-slots {
   margin-right: 30px;
}
.slick-provider {
   padding: 6px 10px 0;
}
.provider-logo-slots img {
   width: 230px;
}
.filterOut {
   display: none;
}
/*Search Box CSS*/
.searchBox {
   height: 40px;
   width: 300px;
   border-radius: 20px;
   border: none;
   font-size: 15px;
   color: #000;
   padding: 0 20px;
   outline: none;
}
/*Filter CSS*/
.selectContainer {
   background: linear-gradient(90deg, #35395e 0%, #35395e 50.5%, #35395e 100%);
   border-radius: 5px;
   transition: all ease 200ms;
   cursor: pointer;
}
.selectContainer:hover {
   margin-bottom: 10px;
}
.filterSelect {
   color: var(--color-white);
   background: none;
   border: none;
   outline: none;
   cursor: pointer;
   font-size: 13px;
   text-transform: uppercase;
   padding: 10px 10px 10px 0px;
   font-weight: 700;
}
.filterDropdown {
   background: #35395e;
   font-size: 13px;
   padding: 20px !important;
   border: none;
   cursor: pointer !important;
}
/*Slots CSS*/
.slot-filter-search-wrapper {
   height: 70px;
}
/*New CSS for GameList*/
.featuredListWrapper {
   display: flex;
   flex-wrap: wrap;
   width: 1300px;
   margin: 0 auto;
   gap: 15px;
   justify-content: flex-start;
}
.gameListWrapper {
   display: flex;
   flex-wrap: wrap;
   width: 1300px;
   margin: 0 auto;
   gap: 15px;
   justify-content: flex-start;
}
.game {
   position: relative;
   height: 200px;
   width: 200px;
   display: grid;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   border-radius: 20px;
   overflow: hidden;
   border: none;
}
.game:hover .gameDetails {
   opacity: 1;
}
.game::before {
   content: "";
   position: absolute;
   background: radial-gradient(circle, #000000, #00000083);
   width: 200px;
   height: 200px;
   opacity: 0;
   z-index: 1;
   border-radius: 20px;
}
.game:hover::before {
   opacity: 1;
   transition: all 500ms ease;
}
.game:hover .gameDetails {
   z-index: 2;
   opacity: 1;
   transition: all 1000ms ease;
}
.gameDetails {
   color: var(--color-white);
   position: absolute;

   width: 200px;
   text-align: center;
   opacity: 0;
   padding: 0 10px;
}
.game button {
   background: radial-gradient(
      52.78% 52.78% at 50% 50%,
      var(--color-white) 0%,
      #f5dd00 100%
   ) !important;
   box-shadow: 0px 0px 3px #ffe600;
   color: #00053a !important;
   text-transform: uppercase;
   font-size: 14px;
   font-weight: 700;
   padding: 10px 0;
   border-radius: 50px;
   width: 150px;
   cursor: pointer;
}
.gameName {
   font-size: 15px;
   font-weight: 700;
   margin-bottom: 5px;
   text-shadow: 10px 10px 10px #000;
   /* Adds a shadow with specified offsets and blur */
}
/*Slick Provider CSS*/
.slot-provider-container .slick-provider {
   padding: 10px 20px 0px;
}
.provider-list-view-all-btn button {
   background: radial-gradient(
      52.78% 52.78% at 50% 50%,
      #ffffff 0%,
      #f5dd00 100%
   ) !important;
   box-shadow: 0px 0px 3px #ffe600;
   color: #00053a !important;
   text-transform: uppercase;
   font-size: 15px;
   font-weight: 700;
   padding: 10px 20px;
   border-radius: 50px;
   cursor: pointer;
}
.slick-provider a {
   outline: none;
}
.slick-provider img {
   filter: opacity(40%);
   transition: filter 0.3s ease-in-out;
   transform: scale(90%);
}
.slick-provider.active img {
   filter: none;
   transform: scale(110%);
}
.slick-provider:hover img {
   filter: none;
}
.slick-provider.active {
   background: linear-gradient(to bottom, rgb(16, 19, 49), rgba(16, 19, 49, 0));
   box-shadow: inset 0px 5px 10px 5px rgba(16, 19, 49, 0);
}
.slick-provider:hover {
   background: linear-gradient(to bottom, rgb(16, 19, 49), rgba(16, 19, 49, 0));
   box-shadow: inset 0px 5px 10px 5px rgba(16, 19, 49, 0);
}
.overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgb(0, 0, 0);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;
}
.loadingBar {
   text-align: center;
   color: white;
}
.spinner img {
   width: 300px;
}
.loader,
.spinner {
   transition: transform 0.3s ease;
}
.loader.pop,
.spinner.pop {
   transform: scale(2);
}
@media (max-width: 840px) {
.loader.pop,
.spinner.pop {
   transform: scale(1.2);
}
}
/*hero-banner*/
.headingTag {
   font-size: 40px;
   text-align: center;
   width: 100%;
}
.slide-description {
   font-size: 20px;
   margin-bottom: 20px;
   text-align: center;
}
.sg8-hero-banner {
   position: relative;
   border-radius: 20px;
   overflow: hidden;
   margin-bottom: 20px;
}
.sg8-hero-banner img {
   width: 100%;
}
.hero-banner-wrapper {
   z-index: 999999;
   position: absolute;
   height: 100%;
   display: grid;
   align-self: center;
}
.heroBannerWrapper-left {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   padding-left: 100px;
   text-align: center;
   left: 0;
   justify-content: center;
}
.heroBannerWrapper-right {
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 50%!important;
   text-align: left!important;
}
.heroBtn {
   background: radial-gradient(
      52.78% 52.78% at 50% 50%,
      #ffffff 0%,
      #f5dd00 100%
   ) !important;
   box-shadow: 0px 0px 3px #ffe600;
   color: #00053a !important;
   text-transform: uppercase;
   font-size: 14px;
   font-weight: 700;
   padding: 2px 0;
   border-radius: 50px;
   cursor: pointer;
   text-align: center;
   margin: 0 auto;
   text-decoration: none;
   padding: 10px 20px;
}

.featured-slots-container {
  padding: 0 0 0 10px;
}

/*Featured LD Games*/
.hotLDGames {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
}
.gameLD {
  position: relative;
  display: grid;
  width: 100%;
  align-items: center;
  justify-items: center;
  cursor: pointer;
}
.gameLD img {
  width: 100%;
}
.gameLD::before {
  content: "";
  position: absolute;
  background: radial-gradient(circle, #000000, #00000083);
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  border-radius: 20px;
}
.gameLD:hover::before {
  opacity: 1;
  transition: all 500ms ease;
}
.gameLD button {
  position: absolute;
  height: 40px;
  width: 130px;
  opacity: 0;
}
.gameLD:hover button {
  z-index: 2;
  opacity: 1;
}
/*updated css mobile homepage*/
@media (max-width: 840px) {
   .featuredListWrapper {
      overflow-x: auto;
      white-space: nowrap;
      width: 100%;
      flex-wrap: nowrap;
      gap: 0;
  }
  .gameListWrapper {
      overflow-x: auto;
      white-space: nowrap;
      width: 100%;
      flex-wrap: wrap;
      gap: 0;
      justify-content: center;
  }
  .game {
      display: inline-block;
      white-space: normal;
      width: 110px!important;
      height: 110px!important;
      margin: 0 5px;
  }
  .gameImg img {
      width: 110px;
      height: 110px;
  }
  .hotLDGames {
    overflow-y: auto;
    white-space: nowrap;
    width: 100%;
    display: flex!important;
    gap: 0;
  }
  .gameLD {
    display: flex;
    white-space: normal;
    width: 200px!important;
    height: 141px!important;
    justify-content: center;
    margin: 0 10px 0 0;
  }
  .gameLD img {
    width: 200px!important;
    height: 141px!important;
  }
  .gameLD button {
    position: absolute;
    height: 40px;
    width: 130px;
    opacity: 0;
  }
  .slots-headlines-container .ld-provider-list,.slot-provider-list,.c2a-wrapper {
      display: none;
  }
  .featured-slots-container .service-selector {
    display: none;
  }
  .h2-text-style {
      line-height: 20px;
      width: 100%;
  }
}


/*css slider*/
.slick-slider {
  margin: 0 -10px; /* Adjust this value to control the visibility of the second slide */
}

.slick-list {
  padding: 0 10px; /* Adjust this value to control the visibility of the second slide */
}

.slick-slide {
  transition: all 0.5s ease; /* Smooth transition effect */
}

.slide-container {
  position: relative;
}

.hero-banner-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}


/*main css*/
.main-wrapper {
  padding: 0!important;
}
.main-content-area {
  width: 100%;
  max-width: 1300px;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0 auto;
  height: 100%;
}
.sg8-hero-banner {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;
}
/*Hero Banner*/
@media (max-width: 840px) {
  .sg8-hero-banner {
    position: relative;
    border-radius: 0;
    overflow: hidden;
    width: 100%;
  }
  .sg8-hero-banner .slide-container img {
    height: 250px;
    object-fit: cover;
  }
  .heroBannerWrapper-right, .heroBannerWrapper-left {
    padding: 0!important;
  }
  .header-news-logout {
    display: none;
  }
}

/*Mobile Footer CSS*/
.navSpan {
  color: yellow;
  font-size: 14px;
}
.mobileNav {
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
}
.mobileFooterNav ul {
  display: flex;
  gap: 0!important;
  text-align: center;
  filter: drop-shadow(0px 5px 10px #F3C00A);
}
.mobileFooterNav ul li {
  text-align: center;
  border: none;
  background: linear-gradient(180deg, #09105e 0%, rgb(9, 14, 78) 19%);
  box-shadow: 2px 0px 100px 20px rgba(0, 0, 0, 0.24);
  width: 100%;
  border-top: 3px solid #F6DD01;
}
.mobileFooterNav img {
  object-fit: contain;
  position: relative;
  top: -17px;
}
.mobileFooterNav p {
  position: relative;
  top: -13px;
}
.sg8-footer-bottom {
  max-width: 1300px;
  margin: auto;
  padding-bottom: 125px;
}
.sg8-footer-main {
  display: flex;
  margin: auto;
  justify-content: space-between;
  max-width: 1300px;
  margin-bottom: 20px;
  padding-top: 40px;
}


/*SportsBook CSS*/
.sportsbook-game-selector {
   height: 100vh;
}
.pageHeaderText h1 {
   color: #fff;
}
.pageHeaderText p {
   color: #f6dd01;
   margin-bottom: 20px;
}
.pageHeaderText button {
   display: inline-block;
   text-align: center;
   background: radial-gradient(
      52.78% 52.78% at 50% 50%,
      #ffffff 0%,
      #f5dd00 100%
   ) !important;
   box-shadow: 0px 0px 3px #ffe600;
   color: #00053a !important;
   text-transform: uppercase;
   font-size: 14px;
   font-weight: 700;
   padding: 10px 20px;
   border-radius: 50px;
   text-decoration: none;
}




#primary {
   position: relative;
   background-size: contain;
   background-repeat: no-repeat;
}
#primary::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: linear-gradient(to bottom,rgba(0, 2, 37, 0.842),rgba(0, 2, 37, 0.774),rgb(0, 2, 37),rgb(0, 2, 37));
   z-index: 1;
}

#main {
   position: relative;
   z-index: 2;
   /* Add any other styles for the content inside #main */
}
